<template>
  <SellersForm
    @save="createSeller"
    :seller="seller"
    :type="'create'"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    SellersForm: () => import('./SellersForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    seller: { type: 'seller' },
    askToLeave: true,
  }),
  methods: {
    createSeller() {
      this.askToLeave = false
      this.$api
        .post('people', this.seller)
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Vendedor cadastrado com sucesso',
            confirm: () => {
              this.$router.push({ name: 'sellers' })
            },
          })
        })
        .catch((err) => {
          let message = ''
          if (err.response.data.message && err.response.data.message.password) {
            message = 'O campo senha é obrigatório'
          } else {
            message = err.response.data.message
          }
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar cadastrar este vendedor',
            caption: message,
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
